import React, { useState, useEffect } from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'
const Navbar = () => {
  const [state, setState] = useState({
    showCollapsedMenu: false
  })
  let location = useLocation();
  let navigate = useNavigate();
  useEffect(() => {
    console.log(location.pathname);
  }, [location])
  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login')
  }

  const toggleMenu = () => {
    setState({
      showCollapsedMenu: !state.showCollapsedMenu
    })
  }
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark ">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">iNoteBook</Link>
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" onClick={toggleMenu}>
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav me-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === "/" ? "active" : ""}`} aria-current="page" to="/">Home</Link>
              </li>
              <li className="nav-item">
                <Link className={`nav-link ${location.pathname === "/about" ? "active" : ""}`} to="/about">About</Link>
              </li>
            </ul>

            {!localStorage.getItem('token') ? <form className="d-flex">
              <Link className="btn btn-primary mx-2" to="/login" role="button">Login</Link>
              <Link className="btn btn-primary mx-2" to="/signup" role="button">Sign-Up</Link>
            </form> : <div className="d-flex text-primary">
              <h6 className='mx-5 my-2'>Welcome Abhiram Krishna S</h6>
              <button onClick={handleLogout} className='btn btn-primary'>Logout</button>
            </div>}

          </div>
        </div>
      </nav>
    </div>
  )
}

export default Navbar