import React  from 'react'
const About = () => {
  
  return (
    <div>
      <h3>This is About Page</h3>
    </div>
  )
}

export default About