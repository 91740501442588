import React from 'react'
import Notes from './Notes.js';
const Home = (props) => {
  const { showAlert } = props;
  return (
    <div className='container'>
      <Notes showAlert={showAlert}/>
    </div>
  )
}

export default Home